import { mixinPromise } from "@c@/helpers/mixin.promise";
import { GET_WECHAT_NEWS_INFO } from "@/store/modules/common/wechat.news.module";

export default {
  methods: {
    /**
     * 获取公众号文章内容
     * @param url
     * @param mutation
     * @param actions
     * @returns {Promise | Promise<unknown>}
     */
    getWeChatNewsInfo(url, mutation = null, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_WECHAT_NEWS_INFO, {url, mutation}),
        actions
      );
    }
  }
}
