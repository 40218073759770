import Swal from "sweetalert2";

export default {
  methods: {
    success( { title = '', message = 'مۇۋاپىقىيەتلىك بولدى!' }){
      return Swal.fire({
        title,
        text: message,
        icon: 'success',
        confirmButtonText: 'بىلدىم',
      });
    },
    warning( { message = 'دېققەت قىلىڭ', title = '' }){
      return Swal.fire({
        title,
        text: message,
        icon: 'warning',
        confirmButtonText: 'بىلدىم',
      });
    },
    error( { message = 'كۈتۈلمىگەن خاتالىق چىقتى!', title = '' }){
      return Swal.fire({
        title,
        text: message,
        icon: 'error',
        confirmButtonText: 'بىلدىم',
      });
    },
  }
}
