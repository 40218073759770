<template>
  <div class="wechat-info-container">
    <b-overlay :show="busy">
      <b-row class="pb-3">
        <b-col sm="6" md="12">
          <b-input-group >
            <b-input-group-append>
              <b-button @click="loadData" :disabled="$v.urlValue.$invalid" v-b-tooltip.hover title="ئۇچۇر ئېلىش" variant="success">
                ئۇچۇرغا ئېرىشىش
              </b-button>
            </b-input-group-append>
            <b-form-input :state="validateState('urlValue', null, null)" aria-describedby="url-live-feedback" v-model="urlValue" @input="changeInput" style="direction: ltr" placeholder="سالون تېمىسى قىسقا ئادېرسىنى كىرگۈزۈڭ" />
            <b-form-invalid-feedback style="direction: ltr; text-align: left;" id="url-live-feedback">
              سالون يازمىسى قىسقا ئادېرسىنى توغرا تولدۇرۇپ قايتا سىناڭ
            </b-form-invalid-feedback>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row v-if="needView && result">
        <b-col v-if="result.data.videos.wxv.length">
          <b-input-group :prepend="`${result.data.videos.wxv.length ? result.data.videos.wxv.length + ' دانە سالون wxv' : 'سالون wxv'}`">
            <b-form-textarea :state="true" readonly rows="1" max-rows="10" v-model.trim="result.data.videos.wxv.join('\n')" style="direction: ltr" placeholder="مەزكۇر تېمىدا سالون ۋىدىئو ئۇچۇرى يوق ئىكەن" />
            <b-input-group-append>
              <b-button :disabled="!result.data.videos.wxv.length" variant="success" @click="copyResult('wxv')">
                كۆچۈرۈش
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col v-if="result.data.videos.vid.length">
          <b-input-group :prepend="`${result.data.videos.vid.length ? result.data.videos.vid.length + ' دانە تېڭشۈن سىنلىرى vid' : 'تېڭشۈن سىنلىرى vid'}`">
            <b-form-textarea :state="true" readonly rows="1" max-rows="10" v-model.trim="result.data.videos.vid.join('\n')" style="direction: ltr" placeholder="مەزكۇر تېمىدا تېڭشۈن سىنلىرى ۋىدىئو ئۇچۇرى يوق ئىكەن" />
            <b-input-group-append>
              <b-button :disabled="!result.data.videos.vid.length" variant="success" @click="copyResult('vid')">
                كۆچۈرۈش
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import validateStateMixin from "../../../mixins/common/validateStateMixin";
  import weChatNewsMixin from "../../../mixins/common/wechat.news.mixin";
  import { required, url } from "vuelidate/lib/validators";
  import sweetAlertMixin from "../../../mixins/common/sweetAlert.mixin";
  export default {
    name: "WeChatNewsInfo",
    props: {
      needView: Boolean,
      value: {
        type: String,
        default: ()=> ''
      },
      autoLoad: Boolean
    },
    computed: {
      urlValue: {
        get(){
          return this.value;
        },
        set(v){
          if ( v !== this.urlValue && v !== this.value ){
            this.$emit('input', v);
            if (this.autoLoad && !this.$v.$anyError){
              this._loadData(v,'computed');
            }
          }
        }
      }
    },
    watch: {
      autoLoad: {
        immediate: true,
        handler(v){
          if ( v ){
            this.$v.urlValue.$touch();
            if (this.$v.urlValue.required && this.$v.urlValue.url && this.urlValue.length)
              this._loadData(null,'auto load');
          }
        },
      }
    },
    mixins: [ weChatNewsMixin, validationMixin, validateStateMixin, sweetAlertMixin ],
    validations: {
      urlValue: { required, url }
    },
    data(){
      return {
        result: null,
        busy: false
      }
    },
    methods: {
      loadData(){
        this.result = null;
        this._loadData();
      },
      _loadData(url = null, who = 'unknown', debug = false){
        this.$v.urlValue.$touch();
        if (!this.$v.urlValue.url) {
          this.warning('سالون يازمىسى قىسقا ئادېرسىنى توغرا تولدۇرۇپ قايتا سىناڭ')
          return;
        }else if (!this.$v.urlValue.required) {
          this.warning('سالون يازمىسى قىسقا ئادېرسىنى چوقۇم تولدۇرۇڭ')
          return;
        }
        this.showBusy();
        debug && console.log(`auto load from: ${who}`);
        debug && console.log(`auto load url: ${url}`);
        this.getWeChatNewsInfo(url ? url : this.urlValue)
          .then(response=>{
            let { data } = response;
            this.result = data;
            this.$emit('success', response);
            this.hideBusy();
          })
        .catch(response=>{
          let { message } = response.response.data;
          this.warning(message);
          this.$emit('fail', response);
          this.hideBusy();
        })
      },
      showBusy(){
        this.busy = true;
      },
      hideBusy(){
        this.busy = false;
      },
      copyResult(type = 'wxv'){
        let { wxv, vid } = this.result.data.videos;
        if ( type === 'wxv' && wxv.length ){
          this.$clipboard(wxv.join("\r"));
          this.success('كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى!');
        }else if( type === 'vid' && vid.length ){
          this.$clipboard(vid.join("\r"));
          this.success('كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى!');
        }
      },
      changeInput(e){
        this.urlValue = e;
        this.$v.urlValue.$touch();
        this.$emit('input', e);
      },
    }
  }
</script>

<style scoped>

</style>
