<template>
  <div class="editor-container">
    <vue-editor
      v-model="editorContent"
      :options="editorConfig"
      :menus="editorMenus"
      @change="onEditorChange"
      :extended-menus="{WeChatNewsInfo: WeChatNewsInfo}"
      :i18next="i18n"
      :height="height"
      :zIndex="zIndex"
      lang="ug"
      ref="editor"
      />
    <parse-we-chat-news-info :state="modalState" v-model="modalUrl" @close="onCloseModal" @success="onParseSuccess" />
  </div>
</template>

<style>
  .w-e-text-container .placeholder {
    text-align: right !important;
    left: unset;
    right: 10px;
  }
</style>

<script>
  import { vueEditor } from 'vue-wangeditor-awesome';
  import { getToken } from "@c@/jwt.service";
  import E from 'wangeditor';
  import i18n from 'i18next'
  import ParseWeChatNewsInfo from "@v@/components/modals/ParseWeChatNewsInfo";

  const { $, BtnMenu } = E;
  class WeChatNewsInfo extends BtnMenu {
    constructor(editor) {
      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
      const $elem = E.$(
      `<div class="w-e-menu" data-title="微信图文内容">
              <i class="fab fa-weixin"></i>
          </div>`
      )
      super($elem, editor);
    }
    clickHandler() {
      // 做任何你想做的事情
      // 可参考【常用 API】文档，来操作编辑器
      let wechat = new Event('wechat');
      if(window.dispatchEvent) {
        window.dispatchEvent(wechat);
      } else {
        window.fireEvent(wechat);
      }
    }
    tryChangeActive() {
      if(this.isActive)
        this.active()
      else if ( !this.isActive )
        this.unActive();
    }
  }

  let ug = {
    wangEditor: {
      插入: 'قىستۇرۇش',
      默认: 'سۈكۈت',
      创建: 'يېڭىدىن قۇرۇش ',
      修改: 'تەھرىرلەش',
      如: 'دەك',
      请输入正文: 'مەزمۇن يېزىشنى باشلاڭ',
      menus: {
        title: {
          标题: 'ماۋزو',
          加粗: 'قېنىقلاشتۇرۇش',
          字号: 'رازمېرى',
          字体: 'فونت',
          斜体: 'يانتو',
          下划线: 'ئاستى سىزىق',
          删除线: 'ئۆچۈرۈش سىزىقى',
          缩进: 'ئالدى بوشلۇق',
          行高: 'قۇر ئېگىزلىكى',
          文字颜色: 'خەت رەڭگى',
          背景色: 'دېكوراتسىيە رەڭگى',
          链接: 'ئۇلانما',
          序列: 'تەرتىپلىك',
          对齐: 'توغۇرلاش',
          引用: 'نەقىل',
          表情: 'مىمىكا',
          图片: 'رەسىم',
          视频: 'سىن',
          表格: 'جەدىۋەل',
          代码: 'كود',
          分割线: 'ئايرىش سىزىقى',
          恢复: 'ئەسلىگە قايتۇرۇش',
          撤销: 'بىكار قىلىش',
          全屏: 'پۈتۈن ئېكران',
          代办事项: 'TODO',
          微信图文内容: 'سالون يازما مەزمۇنىغا ئېرىشىش'
        },
        dropListMenu: {
          设置标题: 'ماۋزو',
          背景颜色: 'دېكوراتسىيە رەڭگى',
          文字颜色: 'خەت رەڭگى',
          设置字号: 'خەت رازمېرى',
          设置字体: 'فونت',
          设置缩进: 'ئالدى بوشلۇق',
          对齐方式: 'توغۇرلاش',
          设置行高: 'قۇر ئېگىزلىكى تەڭشەش',
          序列: 'تەرتىپلىك',
          head: {
            正文: 'مەزمۇن',
          },
          indent: {
            增加缩进: 'ئىچىگىرەك',
            减少缩进: 'سىرتىغىرەك',
          },
          justify: {
            靠左: 'سولغا',
            居中: 'ئوتتۇرغا',
            靠右: 'ئوڭغا',
            两端: 'تەكشىلەش',
          },
          list: {
            无序列表: 'تەرتىپسىز',
            有序列表: 'تەرتىپلىك',
          },
        },
        panelMenus: {
          emoticon: {
            默认: 'سۈكۈت',
            新浪: 'شىنلاڭ',
            emoji: 'emoji',
            手势: 'قول ئىشارىسى',
          },
          image: {
            图片链接: 'رەسىم ئۇلانمىسى',
            上传图片: 'رەسىم يوللاش',
            网络图片: 'توردىكى رەسىم',
          },
          link: {
            链接: 'ئۇلانما',
            链接文字: 'ئۇلانما مەزمۇنى',
            删除链接: 'ئۇلانما ئۆچۈرۈش',
            查看链接: 'ئۇلانما كۆرۈش',
          },
          video: {
            插入视频: 'سىن قىستۇرۇش',
          },
          table: {
            行: 'قۇر',
            列: 'ستون',
            的: ' ',
            表格: 'جەدۋەل ',
            添加行: 'قۇر قوشۇش',
            删除行: 'قۇر ئۆچۈرۈش',
            添加列: 'سىتون قوشۇش',
            删除列: 'ستون ئۆچۈرۈش',
            设置表头: 'قۇر بېشى تەڭشەش',
            取消表头: 'قۇر بېشىنى بىكار قىلىش',
            插入表格: 'جەدىۋەل قىستۇرۇش',
            删除表格: 'جەدىۋەل ئۆچۈرۈش',
          },
          code: {
            删除代码: 'كودنى تازلاش',
            修改代码: 'كودنى تەھرىرلەش',
            插入代码: 'كودنى قىستۇرۇش',
          },
        },
      },
      validate: {
        张图片: 'پارچە رەسىم',
        大于: 'چوڭ',
        图片链接: 'رەسىم ئۇلانمىسى',
        不是图片: 'رەسىم ئەمەس',
        返回结果: 'نەتىجە',
        上传图片超时: 'يۈكلەش ۋاقتى ئېشىپ كەتتى',
        上传图片错误: 'رەسىم يۈكلەشتە خاتالىق بار',
        上传图片失败: 'رەسىم يۈكلەش مەغلۇپ بولدى',
        插入图片错误: 'رەسىم قىستۇرۇشتا خاتالىق بار',
        一次最多上传: 'بىر قېتىمدا ئەڭ كۆپ بولغاندا',
        下载链接失败: 'ئۇلانمىدىن چۈشۈرۈش مەغلۇپ بولدى',
        图片验证未通过: 'رەسىمدە خاتالىق بار',
        服务器返回状态: 'مۇلازىمېتېر قايتۇرغان ھالەت',
        上传图片返回结果错误: 'يۈكلەنگەن رەسىم نەتىجىسىدە خاتالىق بار',
        请替换为支持的图片类型: 'قوللايدىغان فورماتتىكى رەسىمنى تاللاڭ',
        您插入的网络图片无法识别: 'قىستۇرماقچى بولغان توردىكى رەسىمدە خاتالىق بار',
        您刚才插入的图片链接未通过编辑器校验: 'سىز قىستۇرغان رەسىم ئۇلانمىسىدا خاتالىق بار',
      },
    }
  };

  export default {
    name: "Editor",
    components: {vueEditor, ParseWeChatNewsInfo},
    props: {
      value: {
        type: String,
      },
      height: {
        type: [String, Number],
        default: ()=> 300
      },
      zIndex: {
        type: [String, Number],
        default: ()=> 0
      }
    },
    computed: {
      uploadUrl(){
        return process.env.VUE_APP_API_URL + '/v3/manager/image-upload'
      },
      editorContent: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      }
    },
    mounted() {
      window.addEventListener('wechat', (event)=>{
        this.updateModalState(true);
      });

      if (this.$refs.hasOwnProperty('editor'))
        this.editor = this.$refs.editor;
    },
    data(){
      return {
        modalState: false,
        modalUrl: '',
        editorData: '',
        editor: null,
        editorConfig: {
          direction: 'rtl',
          placeholder: null,
          pasteFilterStyle: false,
          uploadImgServer: process.env.VUE_APP_API_URL + '/v3/manager/image-upload',
          uploadImgMaxLength: 1,
          uploadImgParams: {
            strategy: 'editor'
          },
          uploadFileName: 'file',
          uploadImgHeaders: {
            Authorization: `Bearer ${getToken()}`,
          },
          showLinkImg: false,
          uploadImgHooks: {
            // 图片上传并返回了结果，想要自己把图片插入到编辑器中
            // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
            customInsert: function(insertImgFn, result) {
              // result 即服务端返回的接口
              console.log('customInsert', result)
              // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
              insertImgFn(result.url)
            }
          },
          languages: {
            ug
          }
        },
        editorMenus: [
          'head',
          'bold',
          'fontSize',
          'italic',
          'underline',
          'strikeThrough',
          'indent',
          'lineHeight',
          'foreColor',
          'backColor',
          'list',
          'justify',
          'quote',
          'image',
          'table',
          'splitLine',
          'undo',
          'redo',
          'WeChatNewsInfo'
        ],
        WeChatNewsInfo,
        i18n
      }
    },
    methods: {
      onEditorChange(e){
        let { html } = e;
        this.$emit('input', html);
      },
      updateModalState(state = false){
        this.modalState = state;
      },
      onCloseModal(){
        this.updateModalState();
      },
      onParseSuccess(e){
        // console.log(e);
        this.updateModalState();
        this.modalUrl = '';
        let { content } = e.data.data;
        this.editorContent = content;
        // this.$emit('parsed', e);
      }
    },
  }
</script>

<style >
  .w-e-list .w-e-item .w-e-drop-list-tl {
    text-align: right !important;
    padding-left: unset !important;
    padding-right: 10px !important;
    font-size: 14px !important;
  }
</style>
