<template>
  <div class="parse-wechat-news-info">
    <base-modal :id="id" :title="title" :modal-busy="busy" :size="size" v-model="modalState">
      <we-chat-news-info :need-view="false" v-model="urlValue" :auto-load="autoLoad" @success="handleSuccess" @fail="handleFail" />
    </base-modal>
  </div>
</template>

<script>
  import WeChatNewsInfo from "../tools/WeChatNewsInfo";
  import baseModal from "../modals/base.modal";
  export default {
    name: "ParseWeChatNewsInfo",
    props: {
      id: {
        type: String,
        default: ()=> 'parse_wechat_news'
      },
      state: Boolean,
      value: String,
      title: {
        type: String,
        default: () => 'سالون يازما ئۇچۇرىغا ئېرىشىش'
      },
      busy: Boolean,
      size: {
        type: String,
        default: ()=> 'lg'
      },
    },
    computed: {
      modalState: {
        get(){
          return this.state;
        },
        set(v){
          if ( ! v )
            this.hiddenModal();
          return v;
        }
      },
      urlValue: {
        get(){
          return this.value;
        },
        set(v){
          if ( typeof v == 'string')
            this.$emit('input', v);
        }
      },
      autoLoad(){
        if ( typeof this.state == 'boolean' )
          return this.state && !!this.urlValue;
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(v){
          // console.log(v)
        }
      }
    },
    components: { WeChatNewsInfo, baseModal },
    methods: {
      hiddenModal(event) {
        let {id} = this.id;
        this.$emit('close', {id});
      },
      handleSuccess(response){
        this.$emit('success', response)
      },
      handleFail(response){
        this.$emit('fail', response);
      }
    }
  }
</script>

<style scoped>

</style>
