export default {
  methods: {
    validateState(name, parent = null, formName = 'formData') {
      if ( parent ){
        const { $dirty, $error } = formName ? this.$v[formName][parent][name] : this.$v[parent][name];
        return $dirty ? !$error : null;
      }
      const { $dirty, $error } = formName ? this.$v[formName][name] : this.$v[name];
      return $dirty ? !$error : null;
    },
  }
}
